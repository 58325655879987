<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <h3>{{ item.name }}</h3>
      <small class="mb-2 d-block">{{ item.description }}</small>
      <validation-observer ref="editForm" #default="{ invalid }">
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input
            :key="`input-${key}`"
            :fields="fields"
            v-model="data"
          ></n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                :hide-create="true"
                v-if="$can('update', resource)"
              ></n-button-save-option>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";

const ConfigurationRepository = Repository.get("configuration");

export default {
  components: {
    BRow,
    BCol,
    BCard,

    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      key: 1,
      item: {},
      data: {},
      loading: false,
      fields: [],
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      ConfigurationRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.item = { ...data };
          this.fields = [...data.configs];
          let value = {};
          this.fields.forEach((element) => {
            value[element.key] = element.value;
          });

          this.data = {
            ...value,
          };
          this.key++;
        }
      });
    },
    submit(type) {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      let configs = [];
      this.fields.forEach((element) => {
        configs.push({
          key: element.key,
          value: this.data[element.key],
        });
      });
      const data = {
        id: this.data.id,
        configs: configs,
      };
      ConfigurationRepository.update(this.$route.params.id, data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.editForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const resource = "setting";
    const route = "setting";

    return { resource, route };
  },
};
</script>